<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-25">
            <FormSelect
              v-model="filters.status"
              :options="commStatuses"
              label="Status"
              icon="donut_large"
            />
          </div>
          <div class="md-layout-item md-size-25">
            <AgentsSelect
              v-model="filters.agent_id"
              :disabled="userIsAgent && !userIsAgentsManager"
              :agent-groups="agentGroupsManagerIds"
            />
          </div>
          <div class="md-layout-item md-size-25">
            <FormDate
              v-model="filters.from"
              name="from"
              label="Paid Date From..."
            />
          </div>
          <div class="md-layout-item md-size-25">
            <FormDate
              v-model="filters.to"
              name="to"
              label="Paid Date To..."
            />
          </div>

          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="getCommissions()"
            >
              Filter
            </md-button>
            <md-button
              class="md-primary md-sm w-100"
              :disabled="noData"
              @click="downloadFiltered()"
            >
              Download XLS
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <div class="d-inline-block pull-right">
              <md-switch
                v-model="filters.show_fully_paid"
                @change="getCommissions()"
              >
                Show Fully Paid
              </md-switch>
            </div>
          </div>

          <div class="md-layout-item md-size-100 mt-4">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass: 'table',
              }"
              hidden-filters
              hidden-footer
              checkeable
              @checked="onCheck"
              @changed="getCommissions"
              @onShowDetails="onShowDetails"
              @goToProfile="goToProfile"
            />

            <div
              v-if="noData"
              class="p-4 text-center"
            >
              There is no Commissions to show
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <div
      v-if="totalsResume.length"
      class="md-layout justify-content-end"
    >
      <div class="md-layout-item md-size-33 md-small-size-100 p-0">
        <md-card class="mt-0">
          <md-card-header>
            <h5 class="m-0 mt-2 text-center">
              <b>COMMISSIONS TO PAY</b>
            </h5>
          </md-card-header>
          <md-card-content>
            <md-table>
              <md-table-row>
                <md-table-head style="width: 70%">
                  Agent
                </md-table-head>
                <md-table-head>Commission</md-table-head>
              </md-table-row>
              <md-table-row
                v-for="t of totalsResume"
                :key="t.agent_id"
              >
                <md-table-cell>{{ t.agent }}</md-table-cell>
                <md-table-cell>
                  €{{ parseFloat(t.commission).toFixed(2) }}
                </md-table-cell>
              </md-table-row>
              <md-table-row class="font-weight-bold">
                <md-table-cell class="text-right">
                  Total
                </md-table-cell>
                <md-table-cell>
                  €{{
                    totalsResume
                      .reduce((a, b) => a + b.commission, 0)
                      .toFixed(2)
                  }}
                </md-table-cell>
              </md-table-row>
            </md-table>

            <PaymentMethodsSelect v-model="payment_method_id" />

            <md-button
              class="md-success md-block"
              :disabled="!payment_method_id"
              @click="onPayCommissions"
            >
              PAY COMMISSIONS
            </md-button>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <CommissionsDetails
      v-if="showDetails"
      :item="selItem"
      @onCheck="onCheck"
      @onReject="getCommissions"
      @close="closeDetails"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import {
  AgentsSelect,
  PaymentMethodsSelect,
} from '@/components/Inputs/selects';
import { FormDate, FormSelect } from '@/components/Inputs';

import moment from 'moment';
import downloadFile from '@/utils/downloadFile';
import CommissionsDetails from '@/components/Commissions/CommissionsDetails.vue';

export default {
  components: {
    VueTable,
    AgentsSelect,
    FormDate,
    CommissionsDetails,
    FormSelect,
    PaymentMethodsSelect,
  },
  data() {
    return {
      loading: false,
      vTable: {
        headers: [
          {
            title: 'agent',
          },
          {
            title: 'student_number',
            mask: 'student id',
          },
          {
            title: 'full_name',
            mask: 'full name',
          },
          {
            title: 'payment_date',
            mask: 'payment date',
            dateFormat: true,
          },
          {
            title: 'instalment',
          },
          {
            title: 'payment_method',
            mask: 'payment method',
          },
          {
            title: 'income_total',
            mask: 'income total',
          },
          {
            title: 'paid_amount',
            mask: 'paid amount',
          },
          {
            title: 'package_price',
            mask: 'package price',
          },
          {
            title: 'commission',
          },
          {
            title: 'status',
          },
          {
            title: 'submitted_at',
            mask: 'submitted date',
            dateTimeFormat: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-primary',
            tooltip: 'Go To Profile',
            callback: 'goToProfile',
            icon: 'people',
          },
        ],
        values: {},
      },
      filters: {
        agent_id: null,
        from: moment().subtract(31, 'days'),
        to: moment(),
        show_fully_paid: true,
        status: 'UNPAID',
        show_submitted: true,
      },
      commStatuses: [
        { id: 'UNPAID', name: 'UNPAID' },
        { id: 'PAID', name: 'PAID' },
        { id: 'REJECTED', name: 'REJECTED' },
      ],
      showDetails: false,
      selItem: null,
      payment_method_id: null,
    };
  },
  computed: {
    totalsResume() {
      if (!this.vTable.values?.data) return [];

      const rawData = this.vTable.values.data
        .filter((x) => x.checked)
        .map((x) => ({
          agent_id: x.agent_id,
          agent: x.agent,
          commission: x.commission,
        }));

      return Object.values(
        rawData.reduce((a, b) => {
          if (a[b.agent_id]) {
            a[b.agent_id].commission += parseFloat(b.commission);
          } else {
            a[b.agent_id] = {
              agent_id: b.agent_id,
              agent: b.agent,
              commission: parseFloat(b.commission),
            };
          }

          return a;
        }, {}),
      );
    },
    noData() {
      return !this.loading && (!this.vTable.values.data || !this.vTable.values.data.length);
    },
  },
  mounted() {
    if (this.userIsAgent && !this.userIsAgentsManager) {
      this.filters.agent_id = this.user.user_id;
    }

    this.$refs.vtable.init();
  },
  methods: {
    getCommissions() {
      this.loading = true;
      this.request(
        'agents/submitted_commissions',
        'get',
        { ...this.filters, per_page: 1000 },
        ({ data }) => {
          this.vTable.values = {
            current_page: 1,
            from: 1,
            to: 1,
            last_page: 1,
            per_page: 1,
            total: 1,
            data: data.map((x) => {
              x.checked = false;
              x.checkeable = x.status === 'UNPAID';

              return x;
            }),
          };
        },
        () => {
          this.loading = false;
        },
      );
    },
    downloadFiltered() {
      this.loading = true;

      this.request(
        'agents/submitted_commissions',
        'download',
        { download: true, ...this.filters },
        ({ data }) => {
          const filename = `submitted_commissions_${moment().format(
            'YYYYMMDD-HHmmss',
          )}.xlsx`;
          downloadFile(data, filename);
          this.getCommissions();
        },
        () => {
          this.loading = false;
        },
      );
    },
    onPayCommissions() {
      this.loading = true;
      const income_ids = this.vTable.values.data
        .filter((x) => x.checked)
        .map((x) => x.income_id);

      this.request(
        'agents/commissions/pay',
        'post',
        {
          income_ids,
          payment_method_id: this.payment_method_id,
        },
        (data) => {
          this.fireSuccess(data);
        },
        () => {
          this.loading = false;
          this.getCommissions();
        },
      );
    },
    onShowDetails(item) {
      this.selItem = item;
      this.showDetails = true;
    },
    closeDetails() {
      this.selItem = null;
      this.showDetails = false;
    },
    onCheck(e) {
      if (!e.all) {
        if (e.index) {
          this.vTable.values.data[e.index].checked = e.value;
        } else if (e.id) {
          const item = this.vTable.values.data.find(
            (x) => x.income_id === e.id,
          );
          item.checked = e.value;
        }
      } else {
        this.vTable.values.data.map((x) => {
          if (x.checkeable) x.checked = e.value;
          return x;
        });
      }
    },
    goToProfile(item, isMiddleClick) {
      this.openLink({
        name: 'StudentResume',
        params: { id: item.student_id },
      }, isMiddleClick);
    },
  },
};
</script>
